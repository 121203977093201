import React from 'react';

import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';

import { AdvertisingButton } from '@src/components/AdvertisingButton';
import { OptimizedPicture } from '@src/components/ui/OptimizedPicture';
import { PromoInfoBox } from 'entities/Promo/ui/PromoInfoBox/PromoInfoBox';
import { SRAVNI_LOGO_URL } from 'shared/config/logoUrl';

import { PromoDealRedirectToAppButtonData } from './PromoDealRedirectToAppButton.config';

interface Props {
  link: string;
  buttonColor?: ButtonProps['color'];
}

export const PromoDealRedirectToAppButton = ({ link, buttonColor }: Props) => (
  <PromoInfoBox>
    <AdvertisingButton
      advSub={PromoDealRedirectToAppButtonData.advSub}
      affSub4={PromoDealRedirectToAppButtonData.affSub4}
      source={PromoDealRedirectToAppButtonData.sourceAffC}
      size={52}
      link={link}
      color={buttonColor}
      visibleLabel={
        <>
          <OptimizedPicture img={SRAVNI_LOGO_URL} alt="logo" width={15} height={13} />
          {PromoDealRedirectToAppButtonData.getInAppText}
        </>
      }
    />
  </PromoInfoBox>
);
