export const PromoDealRedirectToAppButtonData = {
  getInAppText: 'Получить займ в приложении',
  affSub4: {
    organizationName: 'Получи заём',
    productName: 'Заём',
    organizationId: '6734b6ec375990002a1a1dab',
    productId: '6734b8eba871da0027300346',
  },
  advSub: '030e1737-9334-415f-8b53-96dbde120541',
  sourceAffC: `search|position`,
};
