import React from 'react';

import type { IClientMicrocreditListItem } from '@src/@types/clienttypes';
import type { AffSub4 } from '@src/@types/microcredits';
import { AdvertisingButton } from '@src/components/AdvertisingButton';
import { HAS_OFFER_SOURCE } from 'entities/Analytics';
import { FULL_DEAL_MOBILE_APP_LINKS } from 'features/FullDeal/config';
import { buildHasOfferLinkForFullDeal } from 'features/FullDeal/lib';
import styles from 'features/FullDeal/ui/FullDealCalculatorBottomSheetActions/FullDealCalculatorBottomSheetActions.module.scss';

import { PromoDealOffersActionsTexts } from '../PromoDealOfferActions/PromoDealOffersActions.texts';
import { PromoDealRedirectToAppButton } from '../PromoDealRedirectToAppButton';

interface Props {
  amount: number;
  term: number;
  hasOffer: boolean;
  offer: IClientMicrocreditListItem;
  position: number;
}

export const PromoDealCalculatorBottomSheetActions = ({ amount, term, offer, position }: Props) => {
  // @todo: Хардкод для офера ПС, Егор Ильясов сказал сделать именно так)
  // Получи заём|Заём|6734b6ec375990002a1a1dab|6734b8eba871da0027300346
  const affSub4: AffSub4 = {
    organizationName: 'Получи заём',
    productName: 'Заём',
    organizationId: '6734b6ec375990002a1a1dab',
    productId: '6734b8eba871da0027300346',
  };

  const sourceAffC = `${HAS_OFFER_SOURCE}|position${position}`;

  return (
    <>
      <PromoDealRedirectToAppButton link={FULL_DEAL_MOBILE_APP_LINKS.FROM_MODAL_OFFER} />

      <AdvertisingButton
        variant="secondary"
        advSub={offer.advertising.advSub}
        analytics={offer}
        affSub4={affSub4}
        source={sourceAffC}
        className={styles.advertisingButton}
        size={52}
        link={buildHasOfferLinkForFullDeal(offer.advertising.offerId, amount, term, true)}
        visibleLabel={PromoDealOffersActionsTexts.continueWebsite}
      />
    </>
  );
};
