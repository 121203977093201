import { useRouter } from 'next/router';
import type { RefObject } from 'react';
import React from 'react';

import { Button } from '@sravni/react-design-system';
import type { ButtonProps } from '@sravni/react-design-system/dist/types/components/Button';

import type { AbTestingExperiments } from '@src/constants/abTest';
import { fullDealLandingPageRoute } from '@src/shared/constants/pageRoutes';
import { FULL_DEAL_MOBILE_APP_LINKS } from 'features/FullDeal/config';
import { openWebFullDeal } from 'features/FullDeal/lib';

import { PromoDealRedirectToAppButton } from '../PromoDealRedirectToAppButton';

import { PromoDealOffersActionsTexts } from './PromoDealOffersActions.texts';

interface PromoDealOffersActionsProps {
  amount: number;
  term: number;
  hasOffer: boolean;

  toFullDealButtonText: string;
  toFullDealButtonColor?: ButtonProps['color'];

  seoHeading?: string;
  toFullDealButtonRef?: RefObject<HTMLButtonElement>;
  abTestingExperiments: AbTestingExperiments;
  webFullDealAnalytics?: (buttonLabel: string) => void;
}

export const PromoDealOffersActions = ({
  amount,
  term,
  hasOffer,
  toFullDealButtonText,
  toFullDealButtonColor = 'gray',
  toFullDealButtonRef,
  abTestingExperiments,

  webFullDealAnalytics,
}: PromoDealOffersActionsProps) => {
  const { asPath } = useRouter();
  const [pathWithoutQuery] = asPath.split('?');

  /**
   * новая ссылка на редирект в приложение
   * @param e f
   */
  const link = pathWithoutQuery.includes(fullDealLandingPageRoute)
    ? FULL_DEAL_MOBILE_APP_LINKS.FROM_LANDING
    : FULL_DEAL_MOBILE_APP_LINKS.FROM_REFERAL_VITRINA;

  return (
    <>
      <PromoDealRedirectToAppButton link={link} buttonColor={toFullDealButtonColor} />

      <Button
        variant="secondary"
        color={toFullDealButtonColor}
        size={52}
        onClick={(event) =>
          openWebFullDeal(event, {
            buttonLabel: toFullDealButtonText,
            amount,
            term,
            hasOffer,
            analytics: webFullDealAnalytics,
            abTestingExperiments,
          })
        }
        ref={toFullDealButtonRef}
      >
        {PromoDealOffersActionsTexts.continueWebsite}
      </Button>
    </>
  );
};
